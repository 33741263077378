<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/menu/MenuList')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="菜单名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
  
        <el-form-item label="是否显示在菜单栏">
          <el-switch v-model="form.is_show"></el-switch>
        </el-form-item>

        <el-form-item label="上级菜单">
          <el-cascader
            v-model="form.p_id"
            :options="options"
            :props="{ checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>

        <el-form-item label="路由地址">
          <el-input  v-model="form.url"></el-input>
        </el-form-item>

        <el-form-item label="显示排序">
          <el-input  v-model="form.sort"></el-input>
        </el-form-item>
        
        <el-form-item label="图标">
          <el-input  v-model="form.icon"></el-input>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button @click="goNav('/menu/MenuList')" >取消</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          name: '',
          is_show: 0,
          p_id: 0,
          url: '',
          sort: 1,
          icon: '',
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      postdata.p_id = this.form.p_id[this.form.p_id.length-1];
      if(postdata.p_id == 13){
        postdata.p_id = 0;
      }
      if(postdata.is_show == 0){
        postdata.is_show = 2;
      }else{
        postdata.is_show = 1;
      }
      axios.put(config.menu,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '添加成功',
                type: 'success'
              });
              _this.goNav('/menu/MenuList');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(){
        let _this = this;
        axios.get(config.menu,{params:{page:1}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
         }
          _this.options = arr;
        }
      function dg(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
        }
        return arr;
      }
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
